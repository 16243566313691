<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-settings-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">تنظیمات گروه</div>
      </div>
      <h2 class="text-xl font-bold">
        <Icon name="Group" />
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">
        {{ group.gid }}
      </div>
    </template>
    <template #default>
      <!-- ANCHOR - midlink -->
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-swivel-96.png" class="inline-block h-20" />
          لینک واسط گروه
        </div>
        <div class="text-lg font-bold text-center text-sky-600 mt-3">
          https://t.me/{{ group.bot.username }}?start=group_{{ group.gid }}
        </div>
        <p class="mt-5">
          توسط این لینک کاربران توسط ربات به صفحه ای هدایت می‌شوند که می‌توانند موارد زیر
          را مشاهده کنند.
        </p>
        <ul class="list-inside list-disc mt-5">
          <li>
            <b>توضیحات گروه</b>
            : توضیحات را میتوانید از قسمت پایین تر این صفحه مشخص کنید
          </li>
          <li>
            <b>ادمین های گروه</b>
            : ادمین های گروه که ناشناس نباشند
          </li>
          <li>
            <b>خرید اشتراک گروه</b>
            : اگر برای گروه اشتراک ایجاد کرده باشید کاربران می‌توانند خرید کنند
          </li>
          <li>
            <b>سفارش تبلیغ هدفمند در گروه</b>
            : اگر درتبلیغا هدفمند شرکت کرده باشید کاربران میتوانند سفارش تبلیغ دهند
          </li>
          <li>
            <b>لینک گروه</b>
            : لینک گروه به شرطی که ربات دسترسی اضافه کردن کاربر جدید را داشته باشد.
          </li>
        </ul>
      </div>

      <!-- ANCHOR - report notification -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('report', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-push-notifications-96.png"
            class="inline-block h-20"
          />
          ارسال گزارش
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="group.report_to"
            :options="group.admins"
            oValue="uid"
            oText="first_name"
            oHint="username"
            :dValue="0"
            dText="خاموش"
            placeholder="تنظیمات مورد نظر را انتخاب کنید"
            :search="true"
          />
        </div>

        <template #help>
          <p>
            میتوانید مشخص کنید که ربات به کدام ادمین گزارش ارسال کند. ربات زمانی که پیامی
            را حذف می‌کند یا کاربری را محدود یا اخراج می‌کند به ادمین گزارش ارسال می‌کند.
          </p>
        </template>
      </MainSettingCard>

      <!-- ANCHOR - in out -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('in_out_msg', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-elevator-96.png" class="inline-block h-20" />
          پیام های ورود و خروج
        </div>

        <div class="mt-3">
          <div class="mt-3">
            <MnrCheckSlider v-model:checked="group.del_in_msg">
              حذف پیام های ورود
            </MnrCheckSlider>
            <br />
            <MnrCheckSlider v-model:checked="group.del_out_msg">
              حذف پیام های خروج
            </MnrCheckSlider>
          </div>
        </div>

        <template #help>
          <p>
            از این قسمت می‌توانید مشخص کنید ربات پیام های ورود و خروج کوچک. که در تلگرام
            به صورت پیشفرض ارسال می‌شود را حذف کند.
          </p>
        </template>
      </MainSettingCard>

      <!-- ANCHOR - glass key -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('glass_key', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-footer-96.png" class="inline-block h-20" />
          کلید شیشه‌ای اختصاصی
        </div>
        <MnrCheckSlider v-model:checked="group.glass_key" class="mt-3">
          خاموش
        </MnrCheckSlider>
        <div
          class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3 items-end"
          v-for="(key, index) in group.glassKeys"
          :key="index"
        >
          <MnrText
            v-model="key.text"
            title="متن کلید"
            placeholder="متن کلید را وارد کنید"
            :disabled="!group.glass_key"
          />
          <MnrText
            v-model="key.link"
            title="لینک کلید"
            placeholder="لینک کلید را وارد کنید"
            inputDir="ltr"
            :disabled="!group.glass_key"
          />
          <Button
            @click="group.glassKeys.splice(index, 1)"
            class="mt-3 text-sm h-11 lg:text-lg"
            color="red"
            :disabled="!group.glass_key"
          >
            حذف
          </Button>
        </div>
        <div v-show="group.glassKeys.length < 3">
          <Button
            class="mt-3 text-sm inline-flex"
            :disabled="!group.glass_key"
            color="teal"
            @click="addGlassKey"
          >
            اضافه کردن
          </Button>
        </div>

        <template #help>
          <p>
            این قسمت فقط برای گروه هایی است که امکانات ویژه را فعال کرده اند. از این قسمت
            میتوانند مشخص کنند چه کلید های شیشه ای در زیر پیام هار ربات قرار گیرد.
          </p>
        </template>
      </MainSettingCard>

      <!-- ANCHOR - no ad -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('no_ad', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-ad-blocker-96.png" class="inline-block h-20" />
          حذف یک مورد تبلیغ
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="group.no_ad_type"
            :options="adType"
            oValue="id"
            oText="name"
            :dValue="0"
            dText="همه را ارسال کن"
            placeholder="انتخاب کنید"
            :search="true"
          />
        </div>

        <template #help>
          <div />
        </template>
      </MainSettingCard>

      <!-- ANCHOR - group info-->

      <MainSettingCard
        id="info"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('info', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-info-96.png" class="inline-block h-20" />
          توضیحات گروه
        </div>
        <div class="mt-3">
          <MnrCheckSlider v-model:checked="group.info.ads">
            میخواهم در تبلیغات هدفمند شرکت کنم
          </MnrCheckSlider>
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-3" v-show="group.info.ads">
          <MnrMultiSelect
            v-model="noAd"
            :options="adType"
            class="col-span-2"
            title="تبلیغاتی که نمیخواهید به گروه شما ارسال شوند"
            oValue="id"
            oText="name"
            :dValue="0"
            dText="همه را ارسال کن"
            :showTags="true"
            :someText="group.noAd.length + ' مورد'"
            placeholder="انتخاب کنید"
            :search="true"
          />
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-3" v-show="group.info.ads">
          <MnrNumber
            v-model="group.info.ad_price"
            title="قیمت هر تبلیغ"
            :step="500"
            before="تومان"
          />
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-3 mt-3">
          <MnrTextarea
            v-model="group.info.bio"
            class="col-span-2"
            title="توضیحات کوتاه گروه"
            placeholder="توضیحات در بیو گروه"
            hint="این توضیحات در بیو گروه قرار می‌گیرد"
          />
          <MnrTextarea
            v-model="group.info.info"
            class="col-span-2"
            title="توضیحات بلند گروه"
            placeholder="توضیحات بلند"
            hint="این توضیحات در ربات ذخیره می‌شود و کاربران گروه و افرادی که میخواهند سفارش تبلیغات هدفمند شوند میتوانند در پنل این توضیحات را ببینند"
          />
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-3">
          <MnrSelect
            title="جنسیت غالب گروه"
            v-model="group.info.gender"
            :options="gender"
          />
          <MnrSelect
            v-model="group.info.country_id"
            :options="country"
            oValue="id"
            oText="fa_name"
            :dValue="0"
            dText="نا مشخص"
            title="کشور"
            :search="true"
          />
        </div>
        <div class="grid sm:grid-cols-3 gap-3 mt-3" v-show="group.info.country_id == 118">
          <MnrSelect
            v-model="group.info.province_id"
            :options="province"
            oValue="id"
            oText="name"
            :dValue="0"
            dText="نا مشخص"
            title="استان"
            :search="true"
            :disabled="group.info.country_id != 118"
            placeholder="نا مشخص"
          />
          <MnrSelect
            v-model="group.info.city_id"
            :options="L_.filter(city, ['province_id', group.info.province_id])"
            oValue="id"
            oText="name"
            :dValue="0"
            dText="نا مشخص"
            title="شهر"
            :search="true"
            :disabled="group.info.country_id != 118"
            placeholder="نا مشخص"
          />
        </div>

        <template #help>
          <p>
            از این قسمت میتوانید مشخصات گروه را وارد کنید. این کار برای زمانی مناسب است که
            میخواهید از طریق تبلیغات یا فروش استراک درامد کسب کنید. پس کاربران مشخصات گروه
            شما را مشاهده می‌کنند و تصمیم میگیرند در گروه شما تبلیغات سفارش دهند یا اشتراک
            خرید کنند.
          </p>
        </template>
      </MainSettingCard>

      <!-- ANCHOR - limit time -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('block_time', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-last-72-hours-96.png"
            class="inline-block h-20"
          />
          زمان محدودیت
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            v-model="blockTime.min"
            title="دقیقه"
            before="دقیقه"
            placeholder="0"
            :min="0"
            :max="59"
          />
          <MnrNumber
            v-model="blockTime.hour"
            title="ساعت"
            before="ساعت"
            placeholder="0"
            :min="0"
            :max="59"
          />
          <MnrNumber
            v-model="blockTime.day"
            title="روز"
            before="روز"
            placeholder="0"
            :min="0"
          />
        </div>

        <template #help>
          <p>
            در این قسمت می‌توانید زمان محدودیت ربات را مشخص کنید. که زمانی که در قسمت
            تنظیمات کاربری را محدود می‌کنید بر اساس این زمان محدود شود
          </p>
        </template>
      </MainSettingCard>

      <!-- ANCHOR - submit all -->

      <MainSettingCard
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('all', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-enter-key-96.png" class="inline-block h-20" />
          توجه کنید: ثبت همه
        </div>
        <p>
          شما در هر یک از بخش های بالا می‌توانید تنظیمات آن بخش را ذخیره کنید اما در این
          قسمت میتوانید تنظیمات تمام قسمت ها را به صورت یک جا ذخیره کنید
        </p>
      </MainSettingCard>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrTextarea from "@/components/mnr/MnrTextarea.vue";

import Button from "@/components/Button.vue";
import MnrMultiSelect from "@/components/mnr/MnrMultiSelect.vue";
import MainSettingCard from "@/components/MainSettingCard.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrSelect,
    MnrNumber,
    MnrCheckSlider,
    MnrText,
    MnrTextarea,
    MnrMultiSelect,
    MainSettingCard,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      group: {},
      otherGroup: [],
      adType: [],
      L_: _,
      sending: false,
      country: [],
      province: [],
      city: [],
      noAd: [],
      gender: [
        {
          value: "",
          text: "آقا و خانم",
        },
        {
          value: "male",
          text: "آقا",
        },
        {
          value: "female",
          text: "خانم",
        },
      ],
      blockTime: {
        min: 0,
        hour: 0,
        day: 0,
      },
    };
  },
  watch: {
    blockTime: {
      handler(val) {
        this.group.block_time = val.min + val.hour * 60 + val.day * 60 * 24;
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .get("/api/group-setting/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.adType = response.data.adType;
          $this.otherGroup = response.data.otherGroup;
          $this.country = response.data.country;
          $this.province = response.data.province;
          $this.city = response.data.city;
          $this.noAd = response.data.noAd;

          $this.blockTime.day = Math.floor($this.group.block_time / (24 * 60));
          $this.blockTime.hour = Math.floor(($this.group.block_time % (24 * 60)) / 60);
          $this.blockTime.min = ($this.group.block_time % (24 * 60)) % 60;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
    addGlassKey() {
      this.group.glassKeys.push({
        text: "",
        link: "",
      });
    },
    submit(update, id = this.$route.params.groupId) {
      // console.log(update, id);
      // return;
      let $this = this;
      $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put("/api/group-setting/" + $this.$route.params.groupId, {
          id: $this.$store.state.user.id,
          update: update,
          groupsId: id,
          group: $this.group,
          noAd: $this.noAd,
        })
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
